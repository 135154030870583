<script>
    export default {
        props: ['t', 'r', 'b', 'l', 'y', 'x', 'pr', 'h', 'w', 'vh', 'vw', 'rem'],
    }
</script>
<template>
    <ul class="tx-grey-6 tx-leading-soft">
        <li v-if="t === ''">
            <span class="tx-class">t</span> - hace referencia a <span class="tx-italic">top</span>
        </li>
        <li v-if="r === ''">
            <span class="tx-class">r</span> - hace referencia a <span class="tx-italic">right</span>
        </li>
        <li v-if="b === ''">
            <span class="tx-class">b</span> - hace referencia a <span class="tx-italic">bottom</span>
        </li>
        <li v-if="l === ''">
            <span class="tx-class">l</span> - hace referencia a <span class="tx-italic">left</span>
        </li>
        <li v-if="y === ''">
            <span class="tx-class">y</span> - hace referencia a <span class="tx-italic">top</span> y <span class="tx-italic">bottom</span>
        </li>
        <li  v-if="x === ''">
            <span class="tx-class">x</span> - hace referencia a <span class="tx-italic">right</span> y <span class="tx-italic">left</span>
        </li>
        <li v-if="pr === ''">
            <span class="tx-class">pr</span> - hace referencia a <span class="tx-italic">percentage</span>
        </li>
        <li v-if="h === ''">
            <span class="tx-class">h</span> - hace referencia a <span class="tx-italic">height</span>
        </li>
        <li v-if="w === ''">
            <span class="tx-class">w</span> - hace referencia a <span class="tx-italic">width</span>
        </li>
        <li v-if="vh === ''">
            <span class="tx-class">vh</span> - hace referencia a <span class="tx-italic">viewport height</span>
        </li>
        <li v-if="vw === ''">
            <span class="tx-class">vw</span> - hace referencia a <span class="tx-italic">viewport width</span>
        </li>
        <li v-if="rem === ''">
            <span class="tx-class">1rem</span> - es igual a <span class="tx-italic">16px</span>
        </li>
    </ul>
</template>
