<script>
    export default {    
        props: {
            utilities: Array
        }
    }
</script>
<template>
    <div class="max-h-100 lg:max-w-75pr xl:max-w-50pr overflow-auto mb-12">
        <table class="tx-left w-100pr border-collapse spacing-0" style="margin-top: -1px;">
            <thead>
                <tr>
                    <th class="sticky t-0 tx-semibold bg-white p-0 z-10">
                        <div class="tx-grey-8 py-2 pr-2 border-b-1 border-grey-4 border-t-1">
                            Clase
                        </div>
                    </th>
                    <th class="sticky t-0 tx-semibold bg-white md:w-50pr p-0 z-10">
                        <div class="tx-grey-8 py-2 pr-2 border-b-1 border-grey-4 border-t-1">
                            Propiedades
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tx-14 tx-medium" v-for="(utility, idx) in utilities" :key="idx">
                    <td class="py-2 pr-2 border-b-1 border-grey-2 valign-baseline">
                        <span translate="no" class="tx-class"> {{ utility.class }} </span>
                    </td>
                    <td class="py-2 pr-2 border-b-1 border-grey-2 max-w-50pr">
                        <span translate="no" class="tx-property tx-break-all" v-html="utility.property"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>