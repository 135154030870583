const homeIndex = () => import('@/views/home/Index.vue');
const NotFound = () => import('@/views/NotFound.vue');

const components = () => import('@/views/components/Components.vue');
const componentsIndex = () => import('@/views/components/Index.vue');

const alerts = () => import('@/views/components/inside/Alerts.vue');
const backdrop = () => import('@/views/components/inside/Backdrop.vue');
const boxes = () => import('@/views/components/inside/Boxes.vue');
const burgerTrigger = () => import('@/views/components/inside/BurgerTrigger.vue');
const buttons = () => import('@/views/components/inside/Buttons.vue');
const deletec = () => import('@/views/components/inside/Delete.vue');
const forms = () => import('@/views/components/inside/Forms.vue');
const loader = () => import('@/views/components/inside/Loader.vue');
const modal = () => import('@/views/components/inside/Modal.vue');
const links = () => import('@/views/components/inside/Links.vue');

const utilities = () => import('@/views/utilities/Utilities.vue');
const utilitiesIndex = () => import('@/views/utilities/Index.vue');

const bgAttachment = () => import('@/views/utilities/inside/background/Attachment.vue');
const bgClip = () => import('@/views/utilities/inside/background/Clip.vue');
const bgOrigin = () => import('@/views/utilities/inside/background/Origin.vue');
const bgPosition = () => import('@/views/utilities/inside/background/Position.vue');
const bgRepeat = () => import('@/views/utilities/inside/background/Repeat.vue');
const bgSize = () => import('@/views/utilities/inside/background/Size.vue');

const borderStyle = () => import('@/views/utilities/inside/border/Style.vue');
const borderRadius = () => import('@/views/utilities/inside/border/Radius.vue');
const borderWidth = () => import('@/views/utilities/inside/border/Width.vue');

const flexAlign = () => import('@/views/utilities/inside/flex/Align.vue');
const flexDirection = () => import('@/views/utilities/inside/flex/Direction.vue');
const flexFlex = () => import('@/views/utilities/inside/flex/Flex.vue');
const flexGrow = () => import('@/views/utilities/inside/flex/Grow.vue');
const flexJustify = () => import('@/views/utilities/inside/flex/Justify.vue');
const flexOrder = () => import('@/views/utilities/inside/flex/Order.vue');
const flexShrink = () => import('@/views/utilities/inside/flex/Shrink.vue');
const flexWrap = () => import('@/views/utilities/inside/flex/Wrap.vue');

const autoFlow = () => import('@/views/utilities/inside/grid/AutoFlow.vue');
const autoColumns = () => import('@/views/utilities/inside/grid/AutoColumns.vue');
const autoRows = () => import('@/views/utilities/inside/grid/AutoRows.vue');
const gap = () => import('@/views/utilities/inside/grid/Gap.vue');
const gridColumn = () => import('@/views/utilities/inside/grid/GridColumn.vue');
const gridTemplateColumns = () => import('@/views/utilities/inside/grid/GridTemplateColumns.vue');
const gridRow = () => import('@/views/utilities/inside/grid/GridRow.vue');
const gridTemplateRows = () => import('@/views/utilities/inside/grid/GridTemplateRows.vue');

const outlineStyle = () => import('@/views/utilities/inside/outline/Style.vue');
const outlineWidth = () => import('@/views/utilities/inside/outline/Width.vue');

const height = () => import('@/views/utilities/inside/sizing/Height.vue');
const minHeight = () => import('@/views/utilities/inside/sizing/MinHeight.vue');
const maxHeight = () => import('@/views/utilities/inside/sizing/MaxHeight.vue');
const width = () => import('@/views/utilities/inside/sizing/Width.vue');
const minWidth = () => import('@/views/utilities/inside/sizing/MinWidth.vue');
const maxWidth = () => import('@/views/utilities/inside/sizing/MaxWidth.vue');

const margin = () => import('@/views/utilities/inside/spacing/Margin.vue');
const padding = () => import('@/views/utilities/inside/spacing/Padding.vue');

const tableBorder = () => import('@/views/utilities/inside/table/Border.vue');
const tableLayout = () => import('@/views/utilities/inside/table/Layout.vue');

const transitionDuration = () => import('@/views/utilities/inside/transitions/Duration.vue');
const transitionProperty = () => import('@/views/utilities/inside/transitions/Property.vue');
const transitionTimingFunction = () => import('@/views/utilities/inside/transitions/TimingFunction.vue');

const textAlign = () => import('@/views/utilities/inside/typography/Align.vue');
const textAntialiased = () => import('@/views/utilities/inside/typography/Antialiased.vue');
const textBreak = () => import('@/views/utilities/inside/typography/Break.vue');
const textDecoration = () => import('@/views/utilities/inside/typography/Decoration.vue');
const textFontFamily = () => import('@/views/utilities/inside/typography/FontFamily.vue');
const textLineHeight = () => import('@/views/utilities/inside/typography/LineHeight.vue');
const textListStyle = () => import('@/views/utilities/inside/typography/ListStyle.vue');
const textShadow = () => import('@/views/utilities/inside/typography/Shadow.vue');
const textSize = () => import('@/views/utilities/inside/typography/Size.vue');
const textStyle = () => import('@/views/utilities/inside/typography/Style.vue');
const textTracking = () => import('@/views/utilities/inside/typography/Tracking.vue');
const textTransform = () => import('@/views/utilities/inside/typography/Transform.vue');
const textWeight = () => import('@/views/utilities/inside/typography/Weight.vue');
const textWhiteSpace = () => import('@/views/utilities/inside/typography/WhiteSpace.vue');
const textWritingMode = () => import('@/views/utilities/inside/typography/WritingMode.vue');

const appearance = () => import('@/views/utilities/inside/Appearance.vue');
const boxShadow = () => import('@/views/utilities/inside/BoxShadow.vue');
const colors = () => import('@/views/utilities/inside/Colors.vue');
const cursor = () => import('@/views/utilities/inside/Cursor.vue');
const debug = () => import('@/views/utilities/inside/Debug.vue');
const display = () => import('@/views/utilities/inside/Display.vue');
const filter = () => import('@/views/utilities/inside/Filters.vue');
const float = () => import('@/views/utilities/inside/Float.vue');
const mixBlendMode = () => import('@/views/utilities/inside/MixBlendMode.vue');
const objectFit = () => import('@/views/utilities/inside/ObjectFit.vue');
const opacity = () => import('@/views/utilities/inside/Opacity.vue');
const overflow = () => import('@/views/utilities/inside/Overflow.vue');
const pleaceItems = () => import('@/views/utilities/inside/PleaceItems.vue');
const placement = () => import('@/views/utilities/inside/Placement.vue');
const pointerEvents = () => import('@/views/utilities/inside/PointerEvents.vue');
const position = () => import('@/views/utilities/inside/Position.vue');
const resize = () => import('@/views/utilities/inside/Resize.vue');
const rotate = () => import('@/views/utilities/inside/Rotate.vue');
const scrollBehavior = () => import('@/views/utilities/inside/ScrollBehavior.vue');
const verticalAlign = () => import('@/views/utilities/inside/VerticalAlign.vue');
const visibility = () => import('@/views/utilities/inside/Visibility.vue');
const zIndex = () => import('@/views/utilities/inside/Zindex.vue');

export const routes = [
    {
        path: '', component: homeIndex,
    },
    {   
        path: '/componentes',
        component: components,
        children: [
            { name: 'Componentes', path: '/componentes', component: componentsIndex },

            { name: 'Alertas', path: 'alertas', component: alerts },
            { name: 'Backdrop', path: 'backdrop', component: backdrop },
            { name: 'Boxes', path: 'boxes', component: boxes },
            { name: 'Burger trigger', path: 'burger-trigger', component: burgerTrigger },
            { name: 'Botones', path: 'botones', component: buttons },
            { name: 'Eliminar', path: 'eliminar', component: deletec },
            { name: 'Formulario', path: 'formulario', component: forms },
            { name: 'Loader', path: 'loader', component: loader },
            { name: 'Modal', path: 'modal', component: modal },
            { name: 'Links', path: 'links', component: links },
        ]
    },
    {
        path: '/utilidades',
        component: utilities,
        children: [
            { name: 'Utilidades', path: '/utilidades', component: utilitiesIndex },

            { name: 'Background - Attachment', path: 'background-attachment', component: bgAttachment },
            { name: 'Background - Clip', path: 'background-clip', component: bgClip },
            { name: 'Background - Origin', path: 'background-origin', component: bgOrigin },
            { name: 'Background - Position', path: 'background-position', component: bgPosition },
            { name: 'Background - Repeat', path: 'background-repeat', component: bgRepeat },
            { name: 'Background - Size', path: 'background-size', component: bgSize },

            { name: 'Border - Style', path: 'border-style', component: borderStyle },
            { name: 'Border - Radius', path: 'border-radius', component: borderRadius },
            { name: 'Border - Width', path: 'border-width', component: borderWidth },

            { name: 'Flexbox - Align', path: 'flex-align', component: flexAlign },
            { name: 'Flexbox - Direction', path: 'flex-direction', component: flexDirection },
            { name: 'Flexbox - Flex', path: 'flex', component: flexFlex },
            { name: 'Flexbox - Grow', path: 'flex-grow', component: flexGrow },
            { name: 'Flexbox - Justify', path: 'flex-justify', component: flexJustify },
            { name: 'Flexbox - Order', path: 'flex-order', component: flexOrder },
            { name: 'Flexbox - Shrink', path: 'flex-shrink', component: flexShrink },
            { name: 'Flexbox - Wrap', path: 'flex-wrap', component: flexWrap },

            { name: 'Grid - Grid auto flow', path: 'grid-auto-flow', component: autoFlow },
            { name: 'Grid - Grid auto columns', path: 'grid-auto-columns', component: autoColumns },
            { name: 'Grid - Grid auto rows', path: 'grid-auto-rows', component: autoRows },
            { name: 'Grid - Gap', path: 'gap', component: gap },
            { name: 'Grid - Grid column', path: 'grid-column', component: gridColumn },
            { name: 'Grid - Grid template columns', path: 'grid-template-columns', component: gridTemplateColumns },
            { name: 'Grid - Grid row', path: 'grid-row', component: gridRow },
            { name: 'Grid - Grid template rows', path: 'grid-template-rows', component: gridTemplateRows },

            { name: 'Outline - Style', path: 'outline-style', component: outlineStyle },
            { name: 'Outline - Width', path: 'outline-width', component: outlineWidth },

            { name: 'Sizing - Height', path: 'height', component: height },
            { name: 'Sizing - Min height', path: 'min-height', component: minHeight },
            { name: 'Sizing - Max height', path: 'max-height', component: maxHeight },
            { name: 'Sizing - Width', path: 'width', component: width },
            { name: 'Sizing - Min Width', path: 'min-width', component: minWidth },
            { name: 'Sizing - Max Width', path: 'max-width', component: maxWidth },

            { name: 'Spacing - Margin', path: 'margin', component: margin },
            { name: 'Spacing - Padding', path: 'padding', component: padding },

            { name: 'Table - Border', path: 'table-border', component: tableBorder },
            { name: 'Table - Layout', path: 'table-layout', component: tableLayout },

            { name: 'Transition - Duration', path: 'transition-duration', component: transitionDuration },
            { name: 'Transition - Property', path: 'transition-property', component: transitionProperty },
            { name: 'Transition - Timing function', path: 'transition-timing-function', component: transitionTimingFunction },

            { name: 'Typography - Align', path: 'text-align', component: textAlign },
            { name: 'Typography - Antialiased', path: 'text-antialiased', component: textAntialiased },
            { name: 'Typography - Break', path: 'text-break', component: textBreak },
            { name: 'Typography - Decoration', path: 'text-decoration', component: textDecoration },
            { name: 'Typography - Font family', path: 'text-font-family', component: textFontFamily },
            { name: 'Typography - Line Height', path: 'text-line-height', component: textLineHeight },
            { name: 'Typography - List Style', path: 'text-list-style', component: textListStyle },
            { name: 'Typography - Shadow', path: 'text-shadow', component: textShadow },
            { name: 'Typography - Size', path: 'text-size', component: textSize },
            { name: 'Typography - Style', path: 'text-style', component: textStyle },
            { name: 'Typography - Tracking', path: 'text-tracking', component: textTracking },
            { name: 'Typography - Transform', path: 'text-transform', component: textTransform },
            { name: 'Typography - Weight', path: 'text-weight', component: textWeight },
            { name: 'Typography - White Space', path: 'text-white-space', component: textWhiteSpace },
            { name: 'Typography - Writing Mode', path: 'text-writing-mode', component: textWritingMode },

            { name: 'Appearance', path: 'appearance', component: appearance },
            { name: 'Box shadow', path: 'box-shadow', component: boxShadow },
            { name: 'Colors', path: 'colors', component: colors },
            { name: 'Cursor', path: 'cursor', component: cursor },
            { name: 'Debug', path: 'debug', component: debug },
            { name: 'Display', path: 'display', component: display },
            { name: 'Filter', path: 'filter', component: filter },
            { name: 'Float', path: 'float', component: float },
            { name: 'Mix blend mode', path: 'mix-blend-mode', component: mixBlendMode },
            { name: 'Object fit', path: 'object-fit', component: objectFit },
            { name: 'Opacity', path: 'opacity', component: opacity },
            { name: 'Overflow', path: 'overflow', component: overflow },
            { name: 'Pelace items', path: 'pleace-items', component: pleaceItems },
            { name: 'Placement', path: 'placement', component: placement },
            { name: 'Pointer events', path: 'pointer-events', component: pointerEvents },
            { name: 'Position', path: 'position', component: position },
            { name: 'Rezise', path: 'resize', component: resize },
            { name: 'Rotate', path: 'rotate', component: rotate },
            { name: 'Scroll behavior', path: 'scroll-behavior', component: scrollBehavior },
            { name: 'Vertical align', path: 'vertical-align', component: verticalAlign },
            { name: 'Visibility', path: 'visibility', component: visibility },
            { name: 'Z index', path: 'z-index', component: zIndex },
        ]
    },
    {
        path: '/:pathMatch(.*)', component: NotFound,
    },
]