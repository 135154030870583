import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Prism from "prismjs";

/* STYLE */
import 'plumcss/plumcss.scss';
import 'plumcss/plumcss_colors.scss';
import "./scss/app.scss";

/* GLOBAL COMPONENTS */
Vue.component('table-component', require('@/components/ui/Table.vue').default);
Vue.component('list-references', require('@/components/layout/ListReferences.vue').default);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
